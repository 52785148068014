import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom';
import Home from './components/Home';

function NavigationLink({ to, children, onClick }) {
  const navigate = useNavigate();
  
  const handleClick = (e) => {
    e.preventDefault();
    
    // Create transition text
    const transitionText = document.createElement('div');
    transitionText.className = 'transition-text';
    transitionText.textContent = `Loading ${children}...`;
    document.body.appendChild(transitionText);
    
    // Trigger animation
    setTimeout(() => {
      transitionText.classList.add('active');
      
      // Navigate after animation
      setTimeout(() => {
        document.body.removeChild(transitionText);
        navigate(to);
        onClick();
      }, 1000);
    }, 100);
  };

  return (
    <Link to={to} onClick={handleClick} className="nav-link">
      {children}
    </Link>
  );
}

function App() {
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

    const toggleSidePanel = () => {
        setIsSidePanelOpen(!isSidePanelOpen);
    };

    const closeSidePanel = () => {
        setIsSidePanelOpen(false);
    }

    return (
        <Router>
            <div className="app">
                <div className="top-bar">
                    <div className="site-title">
                        <h1 id="shattered-title">The Shattered Realm</h1>
                        <p>A world where magic and technology intertwine</p>
                    </div>
                </div>
                <div className={`hamburger ${isSidePanelOpen ? 'open' : ''}`} onClick={toggleSidePanel}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className={`side-panel ${isSidePanelOpen ? 'open' : ''}`}>
                    <NavigationLink to="/" onClick={closeSidePanel}>Home</NavigationLink>
                    {/* Commented out for now
                    <NavigationLink to="/deities" onClick={closeSidePanel}>Deities</NavigationLink>
                    <NavigationLink to="/races" onClick={closeSidePanel}>Races</NavigationLink>
                    <NavigationLink to="/subclasses" onClick={closeSidePanel}>Subclasses</NavigationLink>
                    */}
                    <a href="http://35.132.130.76:30000/" className="nav-link" target="_blank" rel="noopener noreferrer">FoundryVTT</a>
                    <a href="https://shatteredrealm.net/user_system/login.php" className="nav-link" target="_blank" rel="noopener noreferrer">Login</a>
                </div>
                <div id="overlay" className={`overlay ${isSidePanelOpen ? 'open' : ''}`} onClick={closeSidePanel}></div>
                <div className="main-content">
                    <div className="content-box">
                        <Routes>
                            <Route path="/" element={<Home />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;