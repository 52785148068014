import React from 'react';

function Home() {
    const homeText = `Welcome to the Shattered Realm
    Welcome, adventurer, to the Shattered Realm—a world irrevocably altered by Humanity's unchecked ambition. This land was once part of numerous distinct realities, each existing in a delicate balance, independent from one another. However, that balance was destroyed when Humanity, driven by its desire for power and control, attempted to harness an entity of immense cosmic force—one that lay far beyond their comprehension. In their hubris, Humanity tore apart the very fabric of existence, causing different dimensions to fracture and merge into what we now call the Shattered Realm.

    In their quest to dominate what they could not truly understand, Humanity set in motion a catastrophic collapse of reality itself. The attempt to trap the cosmic entity using a dimensional net backfired disastrously. This failure did not contain the entity; instead, it shattered the boundaries between countless dimensions, fusing them into one chaotic plane. Fragments of entire worlds collided and layered over each other, creating an unpredictable and unstable environment. What began as Humanity's dream of expansion has since become a desperate struggle for survival amid this bewildering fusion of realms.

    Today, the Shattered Realm is a patchwork of what once was and what should never have been. Floating cities defy gravity, magic-infused jungles entangle the ruins of advanced, long-forgotten technology, and arid deserts whisper with the remnants of spirits from ancient times. Humanity, which once saw itself as a master of its own destiny, now struggles to survive within the fractured world they played a key role in destroying. Reality itself is fragile, akin to a shattered web of interconnected dimensions, constantly shifting and twisting in response to the residual energies of the cataclysm.

    Your Journey
    Your journey through the Shattered Realm will take you into these bizarre and perilous landscapes—places born from the combined dreams and nightmares of countless different worlds. Will you forge alliances with the strange new beings that call this place home? Will you seek to atone for Humanity's past mistakes and help restore balance, or will you exploit the chaos to pursue power for yourself? The Shattered Realm is a world full of dangers and opportunities, and the legacy you leave behind will be shaped by the choices you make in this unpredictable reality.`;
    return (
        <div className="home-content">
            <p>{homeText}</p>
        </div>
    );
}

export default Home;